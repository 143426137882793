// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-[slug]-js": () => import("./../../../src/pages/article/[slug].js" /* webpackChunkName: "component---src-pages-article-[slug]-js" */),
  "component---src-pages-blog-2-js": () => import("./../../../src/pages/blog-2.js" /* webpackChunkName: "component---src-pages-blog-2-js" */),
  "component---src-pages-blog-3-js": () => import("./../../../src/pages/blog-3.js" /* webpackChunkName: "component---src-pages-blog-3-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-single-js": () => import("./../../../src/pages/blog-single.js" /* webpackChunkName: "component---src-pages-blog-single-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index-2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-3-js": () => import("./../../../src/pages/index-3.js" /* webpackChunkName: "component---src-pages-index-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-details-js": () => import("./../../../src/pages/portfolio-details.js" /* webpackChunkName: "component---src-pages-portfolio-details-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-service-details-js": () => import("./../../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

